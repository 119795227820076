


import { Component, Vue } from "vue-property-decorator";
import { Login } from "@/shared/dtos/login";
import { RouterNames } from "@/router/routernames";

@Component({
  $_veeValidate: { validator: "new" },
})
export default class LoginFormulario extends Vue {
  public appTitle = process.env.VUE_APP_TITLE;

  public login!: Login;
  public hide: boolean = true;

  public mounted() {
    this.login = new Login();
    setTimeout(() => (this.hide = false), 1);
  }

  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.tryLogin();
      }
    });
  }

  public fichar() {
    this.$router.push({ name: RouterNames.fichajeform });
  }

  public async tryLogin() {
    const authorizationService = await import(
      "@/shared/services/AuthorizationService"
    );
    authorizationService.authorizationService
      .login(this.login)
      .then(this.loginResult.bind(this));
  }

  private async loginResult(loginOk: Login) {
    if (loginOk.Success) {
      this.hide = true;
      if (loginOk.chg_pwd) {
        setTimeout(() => this.LostPwd(), 250);
      } else {
        setTimeout(() => this.routeToMain(), 250);
      }
    } else {
      const MessageService = await import("@/shared/services/message-service");

      MessageService.MessageService.toast(
        "Usuario o Clave incorrectos",
        MessageService.MessageService.TypeError
      );
    }
  }

  private routeToMain() {
    this.$router.push("/");
  }
  private async LostPwd() {
    const routernames = await import("@/router/routernames");
    this.$router.push({
      name: routernames.RouterNames.lostpwd,
      params: { email: this.login.UserName },
    });
  }
}
